var exports = {};

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};
    var ownKeys = Object.keys(source);

    if (typeof Object.getOwnPropertySymbols === "function") {
      ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function (sym) {
        return Object.getOwnPropertyDescriptor(source, sym).enumerable;
      }));
    }

    ownKeys.forEach(function (key) {
      _defineProperty(target, key, source[key]);
    });
  }

  return target;
}

function _slicedToArray(arr, i) {
  return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest();
}

function _arrayWithHoles(arr) {
  if (Array.isArray(arr)) return arr;
}

function _iterableToArrayLimit(arr, i) {
  var _arr = [];
  var _n = true;
  var _d = false;
  var _e = undefined;

  try {
    for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
      _arr.push(_s.value);

      if (i && _arr.length === i) break;
    }
  } catch (err) {
    _d = true;
    _e = err;
  } finally {
    try {
      if (!_n && _i["return"] != null) _i["return"]();
    } finally {
      if (_d) throw _e;
    }
  }

  return _arr;
}

function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance");
}

var defaultConfig = {
  throwOnInvalid: false
  /**
   * Event validation plugin
   * @link https://getanalytics.io/plugins/event-validation/
   * @param {object}  pluginConfig - Plugin settings
   * @param {string}  pluginConfig.context - Name of app event is in. Example 'api', 'app', 'site', 'cli'
   * @param {boolean} pluginConfig.objects - Objects events can effect
   * @param {boolean} [pluginConfig.throwOnInvalid] - Objects events can effect
   * @return {object} Analytics plugin
   * @example
   *
   * eventValidation({
   *   context: 'app',
   *   objects: ['sites', 'user', 'subscription']
   * })
   */

};

function eventValidationPlugin() {
  var pluginConfig = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return {
    name: "event-validation",
    config: _objectSpread({}, defaultConfig, pluginConfig),
    trackStart: function trackStart(_ref) {
      var payload = _ref.payload,
          config = _ref.config,
          abort = _ref.abort;

      if (!isValidEventName(payload.event, config)) {
        var msg = "invalid event name ".concat(payload.event);

        if (config.throwOnInvalid) {
          throw new Error(msg);
        }

        return abort(msg);
      }
    }
  };
}

function isValidEventName(event, config) {
  var validProject = [config.context] || [];
  var validObjects = config.objects || [];
  var invalid = formatError(event);
  var underscoreCount = contains(event, "_");

  if (underscoreCount !== 1) {
    return invalid("Event must have single underscore. \"".concat(event, "\" contains ").concat(underscoreCount));
  }

  var colonCount = contains(event, ":");

  if (colonCount !== 1) {
    return invalid("Event must have single colon. \"".concat(event, "\" contains ").concat(colonCount));
  }

  var matches = event.match(/([a-zA-Z]+):([a-zA-Z]+)_([a-zA-Z]+$)/);

  if (!matches) {
    return invalid("Event malformed");
  }

  var _matches = _slicedToArray(matches, 4),
      context = _matches[1],
      object = _matches[2],
      action = _matches[3]; // if missing any parts of event, exit;


  if (!context || !object || !action) {
    return invalid("Missing context, object, or action");
  }
  /* Validate project name */


  if (validProject.indexOf(context) === -1) {
    return invalid("Invalid context \"".concat(context, "\". Must be 1 of ").concat(JSON.stringify(validProject)));
  }
  /* Validate object */


  if (validObjects.indexOf(object) === -1) {
    return invalid("Invalid object \"".concat(object, "\". Must be 1 of ").concat(JSON.stringify(validObjects)));
  }

  return true;
}

function contains(str, char) {
  var regex = new RegExp(char, "g");
  return (str.match(regex) || []).length;
}

function formatError(event) {
  return function (msg) {
    console.log("> Invalid event \"".concat(event, "\""));
    if (msg) console.log(msg);
    console.log("> Formatting information:");
    console.log("Event must match pattern \"context:objectName_actionName\"");
    console.log("Event must be camelCased \"camelCase:camelCase_camelCase\"");
    console.log("Example: app:sites_deploySucceeded");
    return false;
  };
}

exports = eventValidationPlugin;
export default exports;